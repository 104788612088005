<template>
  <!-- Start Services -->
  <section class="services" id="services">
    <div class="container">
      <div class="main-title text-center">
        <span class="separator">
          <i class="flaticon-chakra"></i>
        </span>
        <h2>What We Do</h2>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service one">
            <div class="service-bg"><i class="flaticon-lotus"></i></div>
            <div class="service-item">
              <div class="service-icon">
                <i class="flaticon-woman-on-lotus-position-front-view"></i>
              </div>
              <h4>Heal your emotions</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusm tempor incididunt ut labore et.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service two">
            <div class="service-bg"><i class="flaticon-lotus"></i></div>
            <div class="service-item">
              <div class="service-icon">
                <i class="flaticon-woman-stretching-arms-and-flexing-legs"></i>
              </div>
              <h4>Body &amp; Spirituality</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusm tempor incididunt ut labore et.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service three">
            <div class="service-bg"><i class="flaticon-lotus"></i></div>
            <div class="service-item">
              <div class="service-icon">
                <i class="flaticon-woman-stretching-her-body-on-the-floor"></i>
              </div>
              <h4>Refreshens your body</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusm tempor incididunt ut labore et.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service four">
            <div class="service-bg"><i class="flaticon-lotus"></i></div>
            <div class="service-item">
              <div class="service-icon">
                <i class="flaticon-woman-stretching-on-floor"></i>
              </div>
              <h4>Enjoy your life</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusm tempor incididunt ut labore et.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service five">
            <div class="service-bg"><i class="flaticon-lotus"></i></div>
            <div class="service-item">
              <div class="service-icon">
                <i class="flaticon-woman-stretching-back"></i>
              </div>
              <h4>Balance Body &amp; Mind</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusm tempor incididunt ut labore et.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service six">
            <div class="service-bg"><i class="flaticon-lotus"></i></div>
            <div class="service-item">
              <div class="service-icon">
                <i
                  class="flaticon-woman-stretching-and-flexing-legs-with-arms-up"
                ></i>
              </div>
              <h4>Mind &amp; Serenity</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusm tempor incididunt ut labore et.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Services -->
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>